<template>
  <div class="mb-2 text-left">
    <div class="bg-white">
      <div class="bg-orange guest-form-header">
        <div class="text-title --c-white text-center font-weight-bold pt-3 pb-3">
          {{ $t("reservationAndUsageHistory.changeTVPPrePayment") }}
        </div>
      </div>
      <div class="py-4 px-1 mb-4" :class="{'px-4': !inDialog}">
        <div class="mb-4">利用可能ポイント数: {{ remainingTvp | toThousands }} ポイント</div>
        <v-form ref="form">
          <TVPCancelInput v-if="!hideCancelIfZero || totalPenalty" ref="cancelFee" v-model="tvpForCancel"
            title="キャンセル料金"
            :payment-total="totalPenalty"
            :remaining-point="remainingTvp - (newPrePayment || 0)"
          />
          <TVPCancelInput ref="accommodationFee" v-model="newPrePayment"
            title="宿泊料金"
            :payment-total="newAccommodationFee"
            :remaining-point="remainingTvp - (tvpForCancel || 0)"
          />
        </v-form>
        <div class="--c-dark-red">ご宿泊の前日まで変更することができます。</div>

        <div class="text-normal font-weight-bold --c-prm mt-3">お支払い金額合計：{{ paymentAmount | toThousands }}円</div>
        <div v-if="!hideCancelIfZero || totalPenalty" class="payment-row"><div>キャンセル料金</div><div>{{ totalPenalty - tvpForCancel | toThousands }}円</div></div>
        <div class="payment-row"><div>宿泊料金</div><div>{{ newAccommodationFee - newPrePayment | toThousands }}円</div></div>

        <div class="text-normal font-weight-bold --c-prm mt-3">上記差し引き後ポイント残数</div>
        <div>{{ remainingTvpAfterCancel | toThousands }} ポイント</div>
        <div v-if="remainingTvpAfterCancel < 0">
          入力したポイント数が利用可能ポイント数を超えています。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { selectedBookingComputed } from '../../../../selected-booking.mixin'
import TVPCancelInput from './TVPCancelInput.vue'

export default {
  name: 'TVPPrePaymentChangeForm',
  mixins: [selectedBookingComputed],
  props: {
    newAccommodationFee: Number,
    value: Object,
    inDialog: Boolean,
    valid: Boolean,
    totalPenalty: Number,
    hideCancelIfZero: Boolean
  },
  components: {
    TVPCancelInput
  },
  async mounted () {
    await this.$doLoading(async () => {
      await this.$store.dispatch('loadRemainingPoints')
    })
  },
  data () {
    return {
      newPrePayment: 0,
      tvpForCancel: 0
    }
  },
  computed: {
    remainingTvp () {
      return this.$store.getters.remainingTvp + this.remainingPrePayment
    },
    accommodationFeeAfterChange () {
      return this.newAccommodationFee ?? this.currentAccommodationFee.jpy
    },
    totalTvpUsed () {
      return (this.newPrePayment || 0) + (this.tvpForCancel || 0)
    },
    paymentAmount () {
      return this.totalPenalty + this.accommodationFeeAfterChange - this.totalTvpUsed
    },
    remainingTvpAfterCancel () {
      return this.remainingTvp - this.totalTvpUsed
    }
  },
  watch: {
    newPrePayment: {
      immediate: true,
      handler () {
        this.validateAndEmit()
      }
    },
    tvpForCancel: {
      immediate: true,
      handler () {
        this.validateAndEmit()
      }
    },
    value: {
      immediate: true,
      handler (newValue) {
        const { accommodationFee, cancelFee } = newValue || {}
        this.newPrePayment = accommodationFee || 0
        this.tvpForCancel = cancelFee || 0
      }
    },
    newAccommodationFee: {
      immediate: true,
      handler (v) {
        if (v === 0) {
          // then the new value must be zero
          this.$nextTick(() => {
            this.newPrePayment = 0
          })
        }
      }
    },
    totalPenalty: {
      immediate: true,
      handler (v) {
        if (v === 0) {
          // then the new value must be zero
          this.$nextTick(() => {
            this.tvpForCancel = 0
          })
        }
      }
    }
  },
  methods: {
    validateAndEmit () {
      this.$nextTick(() => {
        const isValid = this.$refs.form.validate()
        this.$emit('update:valid', isValid)
        this.$emit('input', {
          accommodationFee: this.newPrePayment,
          cancelFee: this.tvpForCancel
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.payment-row {
  display: flex;
  & > div:first-child {
    width: 130px;
  }
}
</style>
