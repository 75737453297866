<template>
  <div>
    <div class="text-normal font-weight-bold --c-prm">{{ title }}合計</div>
    <div>{{ paymentTotal | toThousands }}円</div>
    <div>{{ title }}に利用するポイント数</div>
    <div class="d-flex align-baseline">
      <div  style="width: 150px">
        <v-text-field type="number" dense flat :rules="[$rules.required, $rules.maxInt(paymentTotal, {
          message: `利用ポイント数が${title}を超えています。`
        }), $rules.maxInt(remainingPoint, {
          message: '利用ポイント数が所有ポイントを超えています。'
        }), $rules.minInt(0)]" v-model="modelValue" />
      </div>
      <div>ポイント</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    paymentTotal: Number,
    value: Number,
    remainingPoint: Number
  },
  computed: {
    modelValue: {
      get () { return this.value },
      set (v) { this.$emit('input', parseInt(v, 10)) }
    }
  }
}
</script>
